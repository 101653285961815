import { VStack } from '@chakra-ui/react'
import { NextPage } from 'next'
import Link from 'next/link'

const Custom404: NextPage = () => {
  return (
    <VStack>
      <h1>404 Not Found</h1>
      <Link href="/login">
        <a>ログイン画面</a>
      </Link>
      <Link href="/cleaning-schedules">
        <a>清掃管理画面</a>
      </Link>
    </VStack>
  )
}

export default Custom404
